import PricingContent from '../../components/shared/PricingContent';

const PricingModal = ({ onClose }) => {
    const buttonText = (plan) => 
        plan.title === 'Free Tier' ? 'Get Started' : 
        plan.title === 'Pro Tier' ? 'Start Free Trial' : 
        'Get Started';

    const handleSelectPlan = async (priceId) => {
        if (!priceId) {
            onClose();
            return;
        } 
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/create-checkout-session`, {      
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ priceId }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            if (data.url) {
                window.location.href = data.url;
            } else {
                throw new Error('No session URL received');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <div className="modal fixed inset-0 flex items-center justify-center bg-black/50 z-50">
            <div className="modal-content bg-white p-8 rounded-lg w-[900px] relative">
                <button onClick={onClose} className="absolute right-4 top-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M18 6L6 18M6 6l12 12" />
                    </svg>
                </button>
                <h2 className="text-4xl font-bold mb-8 text-center">
                    Try for <span className="bg-indigo-500 text-white px-4 py-1 rounded">Free</span>
                </h2>
                <PricingContent onPlanSelect={handleSelectPlan} buttonText={buttonText} />
            </div>
        </div>
    );
};

export default PricingModal;