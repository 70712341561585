import { useState, useEffect } from 'react';
import { pricingPlans, fetchProPrice } from '../../data/pricing';
import { SignUpButton, useUser } from '@clerk/clerk-react';

const PricingContent = ({ onPlanSelect, buttonText = (plan) => 
    plan.title === 'Free Tier' ? 'Get Started' : 
    plan.title === 'Pro Tier' ? 'Start Free Trial' : 
    'Get Started'
}) => {
    const { isSignedIn } = useUser();
    const [prices, setPrices] = useState(pricingPlans);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadPrices = async () => {
            try {
                const proPrice = await fetchProPrice();
                setPrices(currentPrices => 
                    currentPrices.map(plan => 
                        plan.title === 'Pro' 
                            ? { ...plan, price: proPrice }
                            : plan
                    )
                );
            } catch (error) {
                console.error('Error loading prices:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        loadPrices();
    }, []);

    if (loading) return <div>Loading pricing plans...</div>;
    if (error) return <div>Error loading pricing: {error}</div>;

    return (
        <div className="grid grid-cols-2 gap-6 max-w-3xl mx-auto">
            {prices.map((plan) => (
                <div 
                    key={plan.title}
                    className={`border p-6 rounded-lg ${
                        plan.isPopular ? 'border-2 border-indigo-500' : ''
                    }`}
                >
                    <h3 className="text-2xl font-bold mb-2">{plan.title}</h3>
                    <div className="text-3xl font-bold mb-2">
                        {plan.price} {plan.price !== 'Free' && <span className="text-gray-500 text-lg">/mo</span>}
                    </div>
                    
                    <ul className="space-y-3 mb-6">
                        {plan.features.map((feature, index) => (
                            <li key={index} className="flex items-center text-sm">
                                <span className="text-indigo-500 mr-2">✓</span>
                                {feature}
                            </li>
                        ))}
                    </ul>
                    
                    {plan.title === 'Free Tier' ? (
                        isSignedIn ? (
                            <button
                                onClick={() => onPlanSelect(null)}
                                className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-medium"
                            >
                                {typeof buttonText === 'function' ? buttonText(plan) : buttonText}
                            </button>
                        ) : (
                            <SignUpButton mode="modal">
                                <button
                                    className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-medium"
                                >
                                    {typeof buttonText === 'function' ? buttonText(plan) : buttonText}
                                </button>
                            </SignUpButton>
                        )
                    ) : (
                        <button
                            onClick={() => onPlanSelect(plan.priceId)}
                            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-medium"
                        >
                            {typeof buttonText === 'function' ? buttonText(plan) : buttonText}
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default PricingContent; 