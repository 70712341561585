// Cache object to store the price indefinitely
let priceCache = null;

export const pricingPlans = [
    {
        title: 'Free Tier',
        price: 'Free',
        priceId: '0',
        features: [
            '100 searches per month',
            'Subreddit trends so that you can find the 💡 that you\'re looking for',
        ],
        isPopular: false,
    },
    
    {
        title: 'Pro Tier',
        price: 'Loading...', // Default while fetching
        priceId: 'price_1QpgCvGPFt6f57QGIe8dj5s6',
        features: [
            'Unlimited searches per month',
            'Subreddit trends so that you can find the 💡 that you\'re looking for',
            'AI suggestions',
        ],
        isPopular: true,
    }
];

// Initialize price fetching immediately
const initializePriceCache = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-pricing`);
        const prices = await response.json();
        
        const proPrice = prices.find(price => 
            price.product.name.toLowerCase().includes('pro')
        );
        
        if (!proPrice) {
            throw new Error('Pro price not found in Stripe data');
        }

        priceCache = `$${(proPrice.unit_amount / 100).toFixed(2)}`;
        
        // Update the Pro Tier price in pricingPlans
        const proTierPlan = pricingPlans.find(plan => plan.title === 'Pro Tier');
        if (proTierPlan) {
            proTierPlan.price = priceCache;
        }
        
        return priceCache;
    } catch (error) {
        console.error('Error initializing price cache:', error);
        throw error;
    }
};

// Start fetching the price immediately when this module is imported
initializePriceCache().catch(error => {
    console.error('Failed to initialize price cache:', error);
});

export const fetchProPrice = async () => {
    // If we have a cached price, return it
    if (priceCache) {
        return priceCache;
    }

    // If no cache exists yet, fetch price
    return initializePriceCache();
};
