const SubscriberChange = ({ change }) => {
  if (!change) return null;
  
  const { percentageChange } = change;
  const isIncrease = percentageChange >= 0; // Ensures correct boolean evaluation

  const color = isIncrease ? 'text-green-500' : 'text-red-500';
  const arrow = isIncrease ? '↑' : '↓';

  return (
    <span className={`${color} text-sm font-semibold ml-2`}>
      {arrow} {Math.abs(percentageChange)}% /per week
    </span>
  );
};

export default SubscriberChange;

