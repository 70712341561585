import React, { useState, useEffect } from 'react';
import PricingContent from './shared/PricingContent';
import { useAuth } from "@clerk/clerk-react";
const PricingSection = () => {
    const { user } = useAuth();
    const handleSelectPlan = async (priceId) => {
        if (!priceId) return; // Handle free plan selection
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/create-checkout-session`, {      
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ priceId }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            if (data.url) {
                window.location.href = data.url;
            } else {
                throw new Error('No session URL received');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <section className="bg-gray-50 py-20">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold mb-8 text-center">Simple, transparent pricing</h2>
                <PricingContent onPlanSelect={handleSelectPlan} />
            </div>
        </section>
    );
};

export default PricingSection;
