import React, { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Search from './Search';
import { ArrowLeft } from 'lucide-react';
import SubscriberChange from './SubscriberChange';


const AudienceDetail = () => {
  const { audience } = useParams(); // Audience title from the route
  const location = useLocation();
  const navigate = useNavigate(); // Navigate hook for back button functionality

  const subreddits = location.state?.subreddits || JSON.parse(localStorage.getItem(audience)) || [];
  const [searchResults, setSearchResults] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control side menu visibility
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleSearchResults = (results) => {
    // Sort results by `created_utc` in descending order (newest first)
    const sortedResults = results.sort((a, b) => b.created_utc - a.created_utc);
    setSearchResults(sortedResults);
    setIsMenuOpen(true); // Open the side menu when results are received
    analyzeSearchResults(sortedResults); // Call analyze here with search results
  };

  const analyzeSearchResults = async (searchResults) => {
    try {
      setIsAnalyzing(true);
      // Only send essential data
      const simplifiedResults = searchResults.map(post => ({
        title: post.title,
        selftext: post.selftext,
        score: post.score
      }));

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/analyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ searchResults: simplifiedResults }),
      });

      const data = await response.json();
      setAnalysisResult(data.analysis);
    } catch (error) {
      console.error('Error analyzing audience:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-[#1A2238] text-white pt-20">
      {/* Back Button */}
      <div className="container mx-auto p-4">
        <button
          onClick={() => navigate('/dashboard')}
          className="flex items-center gap-2 bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded-full transition-colors"
        >
          <ArrowLeft size={20} />
          <span>Back to Dashboard</span>
        </button>
      </div>

      {/* Search Bar */}
      <div className="container mx-auto p-4">
        <Search subreddits={subreddits} onSearchResults={handleSearchResults} />
      </div>

      {/* Main Subreddit Content */}
      <div className="container mx-auto p-4 bg-white shadow-md rounded-lg text-black">
        <h1 className="text-2xl font-bold mb-4">Subreddits for {audience}</h1>
        <ul>
          {subreddits.map((subreddit, index) => (
            <li key={index} className="mb-6 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={subreddit.icon_img || 'https://via.placeholder.com/40'}
                  alt={`${subreddit.subreddit_name_prefixed} icon`}
                  className="h-10 w-10 rounded-full mr-4"
                />
                <div>
                  <div className="flex items-center gap-2">
                    <h2 className="text-lg font-bold">{subreddit.subreddit_name_prefixed}</h2>
                    {/* Add console.log to debug */}
                    {console.log('subscriberChange:', subreddit.subscriberChange)}
                    <SubscriberChange change={subreddit.subscribersChange} /> 
                  </div>
                  <p className="text-sm">Subscribers: {subreddit.subscribers}</p>
                </div>
              </div>
              <a
                href={`https://www.reddit.com/r/${subreddit.subreddit_name_prefixed}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-pink-500"
              >
                Visit Subreddit
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Side Menu with Overlay for Results */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setIsMenuOpen(false)}
          ></div>

          {/* Results Menu */}
          <div className="fixed top-0 right-0 h-full w-1/2 bg-gray-50 shadow-lg z-50 transition-transform transform flex flex-col">
            {/* Header */}
            <div className="flex items-center justify-between p-4 bg-gray-200">
              <h2 className="text-xl font-semibold text-black">Results</h2>
              <button
                onClick={() => setIsMenuOpen(false)}
                className="flex items-center text-black hover:text-red-500"
              >
                <span className="mr-2 text-lg">X</span>
                Close
              </button>
            </div>

            {/* Search Results List - Takes remaining space */}
            <div className="flex-1 overflow-y-auto p-4">
              <ul>
                {searchResults.map((result, index) => {
                  const postDate = new Date(result.created_utc * 1000);
                  const now = new Date();
                  let formattedDate = postDate.toDateString() === now.toDateString()
                    ? postDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                    : postDate.toLocaleDateString();

                  return (
                    <li
                      key={index}
                      className="relative bg-white p-4 rounded shadow-md mb-4 border"
                    >
                      <a
                        href={`https://www.reddit.com${result.permalink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-lg font-bold text-black hover:text-blue-600 mb-1 block"
                      >
                        {result.title}
                      </a>
                      <p className="text-sm text-gray-600 mb-2 line-clamp-2">
                        {result.selftext || 'No additional text available.'}
                      </p>
                      <div className="text-sm text-gray-500 italic mt-2">
                        {result.subreddit_name_prefixed}
                      </div>
                      {/* Timestamp at the bottom-right corner */}
                      <div className="absolute bottom-2 right-2 text-xs text-gray-400">
                        {formattedDate}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* AI Analysis Section - Sticky at bottom */}
            <div className="border-t border-gray-700 bg-gray-800 text-white p-4">
              <h3 className="text-lg font-semibold mb-2">AI Analysis</h3>
              {isAnalyzing ? (
                <div className="flex items-center justify-center py-4">
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                  <span className="ml-2">Analyzing posts...</span>
                </div>
              ) : analysisResult ? (
                <div className="prose prose-invert text-sm">
                  <div className="whitespace-pre-wrap max-h-32 overflow-y-auto">
                    {analysisResult}
                  </div>
                </div>
              ) : (
                <div className="text-gray-400 text-center py-2">
                  Analyzing search results...
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AudienceDetail;
